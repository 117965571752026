import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import LoadingPage from '../LoadingPage';
import startPageQuery from './StartPageQuery.gql';
import { allContentComponents } from '../ContentComponents/ContentComponents';
import { theme } from '../Theme';
import {FindifyPageTracker} from "../Findify/Tracking";

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 60px;
    ${theme.below.lg} {
      margin-bottom: 40px;
    }
  }
`;

const StartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId }
  });
  const { loading } = result;

  if (loading || !result?.data) return <LoadingPage />;

  return (
    <StartPageWrapper>
      <FindifyPageTracker />
      <StartPageRenderer
        result={result}
        startPageComponents={allContentComponents}
        LoadingPage={LoadingPage}
      />
    </StartPageWrapper>
  );
};

export default StartPage;